import MD5 from 'md5.js';
import { getRouter } from './router';
import * as api from '@/service/api';
import moment from 'moment';
import store from '../store/index';
import cryptoJs from 'crypto-js';
import i18n from '../core/lang';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import router from '../router/index.js';

/**
 * 路由跳转
 * @param {*} url
 */
export function toUrl(url, code) {
  getRouter().push(url);
}

/**
 * 路由替换
 * @param {*} url
 */
export function replaceUrl(url, code) {
  getRouter().replace(url);
}

/**
 * 路由返回
 * @param {*} url
 */
export function back(step = -1) {
  getRouter().back(step);
}

/**
 * cookies--写入
 * @param {String} key 存储键
 * @param {String} value 存储值
 * @param {String} day 过期时间
 *
 */
export function setCookie(key, value, expires, path = '/') {
  console.log('setCookie66666', value, key);
  if (key === 'refresh_token') {
    Cookies.set(key, value);
  } else {
    Cookies.set(key, value, { expires, path, domain: '.tongyuan.cc' });
  }
  // Cookies.set(key, value);
}

/**
 * cookies--读取
 * @param {String} key 存储键
 *
 */
export function getCookie(key) {
  console.log('getkey6666', key);
  return Cookies.get(key);
}

/**
 * cookies--移除
 * @param {String} key 存储键
 *
 */
export function removeCookie(key) {
  // let isTwo = key === 'mw_access_token' || key === 'refresh_token' || key.includes('setPath');
  // if (isTwo) {
  Cookies.remove(key, { domain: '.tongyuan.cc' });
  // } else {
  //   Cookies.remove(key);
  // }
}

/**
 * 设置localStorage
 * @param {*} key
 */
export function setStorage(key, value) {
  return localStorage.setItem(key, JSON.stringify(value));
}

/**
 * 获取localStorage
 * @param {*} key
 */
export function getStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

/**
 * 清除localStorage
 * @param {*} key
 */
export function clearStorage(key) {
  localStorage.removeItem(key);
}

/**
 * 设置sessionStorage
 * @param {*} key
 */
export function setSessionStorage(key, value) {
  return sessionStorage.setItem(key, value);
}

/**
 * 获取sessionStorage
 * @param {*} key
 */
export function getSessionStorage(key) {
  return sessionStorage.getItem(key);
}

// 存要跳转的地址
export function setPath(value) {
  // const compressedValue = LZString.compressToUTF16(value);
  // console.log('压缩后的cookie', compressedValue);
  console.log('字符串长度', value.length);// 3624
  if (value.length > 1300) {
    const segLength = Math.ceil(value.length / 4);
    // 将 value 分成四段
    let segments = [];
    for (let i = 0; i < 4; i++) {
      const start = i * segLength;
      const end = start + segLength;
      segments.push(value.substring(start, end)); // 取值范围：[start, end)
    }
    segments.forEach((segment, index) => {
      setCookie(`setPath${index + 1}`, segment, 1);
      console.log('setCookie2', segment);
    });
  } else {
    console.log('长度短，', value);
    setCookie('setPath', value, 1);
  }
}

export function removePath() {
  const mainValue = getCookie('setPath');
  if (mainValue) {
    removeCookie('setPath');
  } else {
    for (let i = 1; i <= 4; i++) {
      removeCookie(`setPath${i}`);
    }
  }
}

export function setToken(token) {
  setCookie('mw_access_token', token, 7);
}

export function setRefreshToken(token) {
  setCookie('refresh_token', token, 7);
}

export function removeToken() {
  removeCookie('mw_access_token');
  store.dispatch('app/removeToken');
}

export function removeRefreshToken() {
  removeCookie('refresh_token');
  store.dispatch('app/removeRefreshToken');
}

export function removeUserInfo() {
  clearStorage('userInfo');
  store.dispatch('app/removeUserInfo');
}

/**
 * 获取Token
 * @param {*} key
 */
export function getToken() {
  const token = getCookie('mw_access_token');
  console.log('getToken', token);
  if (token && token != 'undefined') {
    // 兼容以前的老的token格式
    if (isEncodedURI(token)) {
      const newToken = JSON.parse(decodeURI(decodeURI(token)));
      setToken(newToken);
      return newToken;
    }
    return token;
  }
  removeUserInfo();
  // clearStorage('authorization')
  return '';
}

export function isEncodedURI(str) {
  try {
    // 尝试解码字符串
    const decodedStr = decodeURI(str);
    return decodedStr !== str;
  } catch (error) {
    // 解码错误，说明字符串无效或已被编码
    return false;
  }
}

/**
 * 是否登陆
 * @param {*}
 */
export function isLogin() {
  let data = getCookie('mw_access_token');
  if (data && data != 'undefined') {
    return true;
  } else if (data == 'undefined') {
    loginOut();
    return false;
  } else {
    return false;
  }
}

/**
 * 登录
 * @param {*} url
 */
export function openLogin(data) {
  console.log('login11111', data, window.location.origin);
  if (data.path) {
    let path = window.location.origin + data.path;
    setPath(path);
  }
  // let host = 'https://login.tongyuan.cc';
  // 本地
  // let redirectUri = 'http://127.0.0.1:9999/callback';
  // let state = 'http://127.0.0.1:9999/';
  // let clientId = '5f2a3cff23c945d58a94';
  // 测试环境
  // let state = window.location.origin + '/';
  // let redirectUri = window.location.origin + '/callback';
  // let redirectUri = 'http://172.16.1.146:9400/callback';
  // let state = 'http://172.16.1.146:9400/';
  let host = process.env.VUE_APP_HOST;
  let clientId = process.env.VUE_APP_CLIENT_ID;
  let state = process.env.VUE_APP_STATE;
  let redirectUri = process.env.VUE_APP_REDIRECT_URI;
  let url = encodeURIComponent(
    `${host}/authentication/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=profile openid email&state=${state}`
  );
  window.location.href = `${host}/login?redirect_to_url=${url}`;
}

/**
 * 退出登录
 * @param {*} url
 */
export function loginOut() {
  removeToken();
  removeRefreshToken();
  removeUserInfo();
  removePath();
  // clearStorage('authorization');
  // location.reload();
  // openLogin();
}

/**
 * 登陆
 *
 */
export function login(res, path = '/') {
  system.token = res.token;
  system.userInfo = res.data;
  store.dispatch('app/setUserInfo', res.data);
  if (path) {
    window.location.href = `/${path}${window.location.search}`;
  }
}

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

/**
 * changeTitle
 * @desc 修改浏览器标签页标题title
 * @param {String} value
 *
 */
export function changeTitle(value) {
  document.title = value;
}

/**
 * 用户信息--读取
 *
 */
export function getUserInfo() {
  if (isLogin()) {
    return getStorage('localStorage', 'userInfo');
  }
  return '';
}

// 登录后重新跳转111
export async function asyncGetUserInfo(data) {
  const res = await api.userinfo();
  store.dispatch('app/setUserInfo', res.data);
  console.log('获取的时候111111', data);
  const mainValue = getCookie('setPath');
  // const mainValue = data.state;
  // const setPath = getCookie('setPath');
  let setPath;
  // 如果有cookie，跳转页面就用cookie，没有就用state
  // 不能用state，因为state有？接口会报错
  if (mainValue) {
    setPath = mainValue;
  } else {
    const segments = [];
    for (let i = 1; i <= 4; i++) {
      let segment = getCookie(`setPath${i}`);
      console.log('segment', segment);
      if (segment) {
        segments.push(segment);
      }
    }
    console.log('segments', segments);
    if (segments.length > 0) {
      setPath = segments.join('');
    }
  }

  console.log('setPath888', setPath);
  removePath();
  if (setPath) {
    // TODO 登录后跳转要支持跳转到帮助文档
    // this.$utils.removeCookie('setPath')
    // router.router.push(setPath);
    window.location.href = setPath;
  } else {
    console.log('router.router', router.router.history.current);
    router.router.push('/');
  }
}

/**
 * crypto-js DES加密方法
 */
export function encryptDes(message, key) {
  let keyHex = cryptoJs.enc.Utf8.parse(key);
  let option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
  let encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
  return encrypted.toString();
}
export function decryptDes(message, key) {
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var decrypted = cryptoJs.DES.decrypt(
    {
      ciphertext: cryptoJs.enc.Hex.parse(message)
    }, // 若 message 是 base64 格式，则无需转16进制hex，直接传入 message 即可
    keyHex,
    {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7
    }
  );
  return decrypted.toString(cryptoJs.enc.Utf8);
}

export function generateSalt(length) {
  var char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var chars = '';
  for (var i = 0; i < length; i++) {
    chars += char.charAt(parseInt(Math.random() * char.length));
  }
  return chars;
}

export function generatePassword(rawPassword) {
  var salt = this.generateSalt(8);
  var base64Salt = Buffer.from(salt, 'utf-8').toString('base64');

  var desPassword = this.encryptDes(rawPassword, base64Salt.substring(0, 8));
  return Buffer.from(desPassword + salt, 'utf-8').toString('base64');
}

/**
 * 刷新登陆token
 */
export async function refreshToken() {
  let refToken = getCookie('refresh_token');
  console.log('refreshToken000', refToken);
  if (isLogin() && refToken) {
    let res = await api.refreshToken({
      refreshToken: getCookie('refresh_token')
    });
    if (res.code == 200 && res.data.access_token && res.data.refresh_token) {
      store.dispatch('app/setToken', res.data.access_token);
      store.dispatch('app/setRefreshToken', res.data.refresh_token);
    }
  }
}

/**
 * md5加密
 * @param {*}
 */
export function md5(key) {
  return new MD5().update(key).digest('hex');
}

/**
 * 异步获取组件
 */
export function getComponent(path) {
  return () => {
    try {
      let len = path.match(/\//g).length;
      let reg = /index$/g;
      if (path == '/') {
        path = system.index;
      } else if (len == 1 && !reg.test(path)) {
        path += '/index';
      }
      path = system.pageView + path;
      let page = import(`@/${path}`);
      page.then((res) => {});
      page.finally(() => {});
      page.catch((err) => {
        console.log(err, 'err');
        getRouter().replace('/404?path=' + path);
      });
      return page;
    } catch (error) {
      console.log(error, 'getComponent');
    }
  };
}

/**
 * 创建定时器
 *
 */
export function createTimer(fun, t) {
  const timer = setInterval(() => {
    fun();
  }, t * 1000);
  return timer;
}

/**
 * 清除定时器
 *
 */
export function clearTimer(timer) {
  if (timer) {
    clearInterval(timer);
  }
}

/**
 * 计算距离当前时间
 * @param {String} cellValue
 */
export function friendlyDate(cellValue) {
  if (cellValue === null || cellValue === undefined) {
    return '';
  }
  const timestamp = new Date(parseInt(cellValue));
  var formats = {
    year: `%n% ${i18n.t('utils.year')}`,
    month: `%n% ${i18n.t('utils.months')}`,
    day: `%n% ${i18n.t('utils.day')}`,
    hour: `%n% ${i18n.t('utils.hour')}`,
    minute: `%n% ${i18n.t('utils.minutes')}`,
    second: `%n% ${i18n.t('utils.seconds')}`
  };
  var now = Date.now();
  var seconds = Math.floor((now - timestamp) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  var months = Math.floor(days / 30);
  var years = Math.floor(months / 12);
  var diffType = '';
  var diffValue = 0;
  if (years > 0) {
    diffType = 'year';
    diffValue = years;
  } else {
    if (months > 0) {
      diffType = 'month';
      diffValue = months;
    } else {
      if (days > 0) {
        diffType = 'day';
        diffValue = days;
      } else {
        if (hours > 0) {
          diffType = 'hour';
          diffValue = hours;
        } else {
          if (minutes > 0) {
            diffType = 'minute';
            diffValue = minutes;
          } else {
            diffType = 'second';
            diffValue = seconds <= 0 ? (seconds = 1) : seconds;
          }
        }
      }
    }
  }
  return formats[diffType].replace('%n%', diffValue);
}

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function currentDate() {
  return moment().format('YYYY-MM-DD HH:mm:ss');
}

/**
 * 下载方法
 * @param {Request} xhr
 */
export function initXhr(xhr, fn) {
  xhr.onload = function () {
    // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
    if (this.status === 200) {
      notify(i18n.t('project.download'), 'warning');
      var blob = this.response;
      const totalName = xhr.getResponseHeader('content-disposition');
      let fileName = decodeURI(totalName.split(';')[1].split('fileName=')[1]);
      var link = document.createElement('a');
      link.download = fileName;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blob);
      document.body.removeChild(link);
      if (fn) {
        fn();
      }
    }
  };
}

/**
 * 中文/utf-8互转
 * @param {String} value
 * @param {String} target
 */
export function zhToUtf8(value, target) {
  if (target == 'zh') {
    // return unescape(value.replace(/&#x/g, '%u').replace(/;/g, ''));
    // return unescape(value.replace(/&#x/g, '%u')); // 这里分号不进行转换
    let x = '';
    let wch = '';
    let wch1 = '';
    let wch2 = '';
    let uch = '';
    let szRet = '';
    for (x = 0; x < value.length; x++) {
      if (value.charAt(x) == '%') {
        wch = parseInt(value.charAt(++x) + value.charAt(++x), 16);
        if (!wch) {
          break;
        }
        if (!(wch & 0x80)) {
          wch = wch + '';
        } else if (!(wch & 0x20)) {
          x++;
          wch1 = parseInt(value.charAt(++x) + value.charAt(++x), 16);
          wch = (wch & 0x1f) << 6;
          wch1 = wch1 & 0x3f;
          wch = wch + wch1;
        } else {
          x++;
          wch1 = parseInt(value.charAt(++x) + value.charAt(++x), 16);
          x++;
          wch2 = parseInt(value.charAt(++x) + value.charAt(++x), 16);
          wch = (wch & 0x0f) << 12;
          wch1 = (wch1 & 0x3f) << 6;
          wch2 = wch2 & 0x3f;
          wch = wch + wch1 + wch2;
        }
        szRet += String.fromCharCode(wch);
      } else {
        szRet += value.charAt(x);
      }
    }
    return szRet;
  }
  return escape(value).replace(/(%u)(\w{4})/gi, '&#x$2;');
}

/**
 * 格式化数字-input限制输入非数字字符(禁止滚轮和上下箭头事件)
 * @param {String} evt
 *
 */
export function format(evt, type) {
  let reg;
  if (type == 'float') {
    // 浮点数
    reg = /[-\d.]/;
  } else if (type == 'positiveFloat') {
    // 正浮点数
    reg = /[\d.]/;
  } else if (type == 'positiveInteger') {
    // 正整数
    reg = /[\d]/;
  }
  // console.log(evt.keyCode, String.fromCharCode(evt.keyCode));
  if (!reg.test(String.fromCharCode(evt.keyCode))) {
    evt.preventDefault();
    return false;
  }
}

export function getTimeDf(begintime, endtime) {
  const timestamp1 = new Date(parseInt(begintime));
  const timestamp2 = new Date(parseInt(endtime));
  var date3 = timestamp2 - timestamp1; // 时间差的毫秒数
  // 计算出相差天数
  var days = Math.floor(date3 / (24 * 3600 * 1000));
  // 计算出小时数
  var leave1 = date3 % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000));
  // 计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000));
  // 计算相差秒数
  var leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000);
  // alert(" 相差 "+days+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒")
  let timeDf = '';
  if (days > 0) {
    timeDf += days + i18n.t('utils.day1');
  }
  if (hours > 0) {
    timeDf += hours + i18n.t('utils.hour1');
  }
  if (minutes > 0) {
    timeDf += minutes + i18n.t('utils.minutes1');
  }
  if (seconds > 0) {
    timeDf += seconds + i18n.t('utils.seconds1');
  }
  return timeDf;
}

// 时间戳转时间
export function dateFormatterStamp(row, column, cellValue, index) {
  if (!cellValue) {
    return '';
  }
  const t = new Date(parseInt(cellValue));
  // 日期格式
  const format = 'Y-m-d h:i';
  let year = t.getFullYear();
  // 由于 getMonth 返回值会比正常月份小 1
  let month = t.getMonth() + 1;
  let day = t.getDate();
  let hours = t.getHours();
  let minutes = t.getMinutes();
  let seconds = t.getSeconds();

  const hash = {
    y: year,
    m: month,
    d: day,
    h: hours,
    i: minutes,
    s: seconds
  };
  // 是否补 0
  const isAddZero = (o) => {
    return /M|D|H|I|S/.test(o);
  };
  return format.replace(/\w/g, (o) => {
    let rt = hash[o.toLocaleLowerCase()];
    return rt > 10 || !isAddZero(o) ? rt : `0${rt}`;
  });
}

// 节流函数
export function throttle(func, delay) {
  var prev = Date.now();
  return function () {
    var context = this; // this指向window
    var args = arguments;
    var now = Date.now();
    if (now - prev >= delay) {
      func.apply(context, args);
      prev = Date.now();
    }
  };
}

// 防抖函数
export function debounce(fn, delay) {
  let timer = null;
  return function () {
    if (timer) clearTimeout(timer);
    const _this = this;
    const _arguments = arguments;
    timer = setTimeout(function () {
      console.log(fn, 'fn');
      fn.apply(_this, _arguments);
    }, delay);
  };
}

/**
 * reg
 * @des 正则(手机号/邮箱)
 *
 */
export function reg(type, value) {
  let reg;
  switch (type) {
  case 'phone':
    reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g;
    break;
  case 'mail':
    reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;
    break;
  case 'positiveInteger':
    reg = /[\d]/;
  }
  return reg.test(value);
}

/**
 * 隐藏字符串中间4位
 * @param {String} value
 *
 */
export function hideStr(value) {
  if (value.includes('+86')) {
    value = value.substring(3);
  }
  const reg = value.substring(3, 7);
  return value.replace(reg, '****');
}

/**
 * urlToBase64
 * 二进制文件转base64
 * @param {String} value
 *
 */
export function urlToBase64(value) {
  let url = value;
  if (value.indexOf('base64') < 0) {
    const temp = Base64.decode(value);
    if (temp.includes('PNG')) {
      url = 'data:image/png;base64,' + value;
    } else if (temp.includes('BM')) {
      url = 'data:image/bmp;base64,' + value;
    } else if (temp.includes('GIF')) {
      url = 'data:image/gif;base64,' + value;
    } else if (temp.includes('JP')) {
      url = 'data:image/jpg;base64,' + value;
    } else {
      url = 'data:image/svg+xml;base64,' + value;
    }
  } else {
    url = value.replace('data:image/svg', 'data:image/svg+xml');
  }
  return url;
}
